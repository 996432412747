import { Directive, Input, OnInit, ElementRef, Optional, Inject } from '@angular/core';
import { EvaluationTimes } from '../models/enums/evaluation-times';
import { CalculatedExpression } from '../models/calculated-expression';
import { CodeHelper } from 'src/app/@core/services/CodeHelper.service';
import { CF_COMPONENT, IConditionalFormattingComponent } from './condition-formatting.directive';
import { HttpClient } from '@angular/common/http';

@Directive({
  selector: '[zappCfcalculatedExpression]'
})
export class CalculatedExpressionDirective implements OnInit {

  private expressions: Array<CalculatedExpression>;
  private _model: any;
  private _context: any;
  private _dependencies?: any[];
  private _controlName: string;
  private _onInitHasRun = false;
  private _modelIsReady = false;
  private _component: IConditionalFormattingComponent;

  constructor(private element: ElementRef, private codeHelper: CodeHelper, private httpClient: HttpClient,
    @Optional() @Inject(CF_COMPONENT) component: IConditionalFormattingComponent | null) {

    if (component?._elementRef != null && component?._elementRef?.nativeElement === element.nativeElement) {
      this._component = component;
    }

  }

  ngOnInit(): void {

  }

  @Input()
  zappRulesAllControls: any;

  @Input()
  set zappCfcalculatedExpressionDependencies(dependencies: any[]) {
    this._dependencies = dependencies;
    this.executeRules();
  }

  @Input()
  set zappCfcalculatedExpressionControlName(controlName: string) {
    this._controlName = controlName;
  }

  @Input()
  set zappCfcalculatedExpressionModel(model: any) {
    this._model = model;
    this._modelIsReady = model != null && Object.keys(model).length > 0;
    this.executeRules();
  }

  @Input()
  set zappCfcalculatedExpressionContext(context: Array<any>) {
    this._context = context;
    this.executeRules();
  }

  @Input()
  set zappCfcalculatedExpression(rules: Array<CalculatedExpression>) {
    this.expressions = rules;
  }

  @Input()
  set zappCfcalculatedExpressionActivePage(url: string) {
    if (url == null) {
      return;
    }

    this._onInitHasRun = false;
    this._modelIsReady = false;
  }

  @Input()
  set zappCfcalculatedExpressionTrigger(trigger: number) {
    this.executeRules();
  }

  executeRules(): boolean {
    if (this._modelIsReady == false) {
      return false;
    }

    var evaluationTime = EvaluationTimes.OnChange;

    if (this._onInitHasRun == false) {
      evaluationTime = EvaluationTimes.OnLoad;
      this._onInitHasRun = true;
    }

    //TODO: filter rules that can affect this control
    var rulesToRun = this.expressions.filter(r => r.evaluationTimes.indexOf(evaluationTime) != -1 && r.controls.indexOf(this._controlName) != -1);
    if (rulesToRun.length === 0) {
      return false;
    }

    for (let i = 0; i < rulesToRun.length; i++) {
      const rule = rulesToRun[i];
      if (rule.contextControlName != null && this._context == null) {
        continue;
      }

      rule.expression(this.codeHelper, this._model, this._context, this.httpClient, this.zappRulesAllControls).then(
        (res: any) => {
          if (res == null) {
            return;
          }

          if (this._component?.setValue != null) {
            this._component.setValue(res, true);
          } else {
            if (this.element.nativeElement.nodeName == 'INPUT') {
              this.element.nativeElement.value = res;
            } else {
              this.element.nativeElement.textContent = res;
            }
          }
        }
      )
    }

    return true;
  }
}
